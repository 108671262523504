import React from 'react';
import './ContPage.css';

const MusicPage = () => {
  return (
    <div className="music-page">
      <div className="header-box">
        <h1>music page</h1>
      </div>
      <div className="content-box">
        <h2>part 1</h2>
        <p>i haven't made much of my music public</p>
      </div>
      <div className="content-box">
        <h2>part 2</h2>
        <p>i don't even record really</p>
      </div>
      <div className="content-box">
        <h2>part 3</h2>
        <p>having a whole page for this is a bit extra</p>
      </div>
      <div className="content-box">
        <h2>special projects</h2>
        <p>the special part is because maybe one special day i'll actually have something to put here</p>
      </div>
    </div>
  );
};

export default MusicPage;