import React, { useEffect, useState } from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100); // Show the name after a short delay
    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="home-page">
      <div className={`name-container ${isVisible ? 'visible' : ''}`}>
        <h1>phoebe</h1>
        <h1>alexandria</h1>
        <h1>corwin</h1>
      </div>
      <div className={`text-box ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <p>choose</p>
        <p><span className="arrow">▼</span></p>
        <div className="dropdown-menu">
          <Link to="/about">about me</Link>
          <Link to="/vlog">b/v log</Link>
          <Link to="/code">code</Link>
          {/* <Link to="/music">music</Link> */}
          {/* <Link to="/photography">photography</Link> */}
        </div>
      </div>
    </div>
  );
};

export default HomePage;