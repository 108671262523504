import React from 'react';
import './ContPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="header-box">
        <h1>about</h1>
        <a href="/" className="home-button">home</a>
      </div>
      <div className="content-box">
        <h2>me</h2>
        <p>
            hello! my name is phoebe alexandria corwin. i've gone by phoebe for a while but i prefer alex these days.
        </p>
        <p>
            i'm a software developer with a pretty broad background in computer science, but the area of focus for my degree was machine learning methods. i'd call myself a full-stack developer
            but i try not to get caught up in semantics. i develop software: web systems (frontend, backend, database), data analysis/management tools, machine learning processes, you name it. 
        </p>
        <p>
            this site is going to serve as a source of info about me because i usually delete my socials like once a month. i graduated from 
            the university of vermont in 2021. around the same time, i began treatment for a tumor that had been hiding in my brain since i was a teenager. talk about timing!
            i found some great medical care in nyc, where i live now, and things are finally copacetic on the neurological front.
        </p>
        <p>
            in my professional experience, i worked as a data specialist developing data operations infrastructure for marketing and product. this
            involved data collection and aggregation, as well as transforming and loading the data to the preexisting systems. i also
            initiated the process of documenting the very mature database and related systems.
        </p>
        <p>
            a professor i had in college emphasized that having studied computer science, you shouldn't limit yourself to one programming 
            language when describing your abilities. i've found this to be true; i do primarily work with python and sql, but i've found i can pick up 
            new tools and languages quickly. i've worked with c/c++ for os simulations, gave myself a brief overview in rust for fun, and made 
            this site in react. i also did some work with c# during the pandemic to make a game in unity. for one project, on a team of four, we used mongodb and 
            swift to make a functional ios app for step-tracking. 
        </p>
        <p>
            my other hobbies! i love taking photos when i find a subject that piques my interest, recently it's been portraits. i also like to read lots of things simultaneously;
            i'm currently working my way through "Inflamed", "Breakfast of Champions", and "The Ascent of Money", as well as the documentation and research i have open on my laptop. 
            i'm curious by nature so the research is usually pretty scattered in terms of topics. recently, it's been the roles of different neurotransmitters in learning and 
            relativistic quantum mechanics. i've been a big fan of neuroscience since working with neural networks a few years back- i know they're only tangentially related, 
            but i'm fascinated by the human reward function. and the physics stuff is just for the fun/existential dread of it. 
        </p>
        <p>
            these days, if i'm not working on a project that requires otherwise, i spend most of my time offline. i disconnected from social media and similar platforms during the early
            days of the pandemic and later reconnected in an intentfully more utilitarian way. i don't like thinking of my cell phone as an extension of myself.
        </p>
        <p>
            if you or someone you know is looking for a developer who can pick up new tools on the fly and easily pivot between different roles,
            i'm currently open to new career opportunities and my email/linkedin/github is below.
        </p>
        </div>
        <div className="content-box">
            <h2>contact</h2>
            <p>email:
                <a href="mailto:phoebe.corwin23@gmail.com">             phoebe.corwin23@gmail.com</a>
            </p>
            <p>linkedin:
                <a href='https://www.linkedin.com/in/phoebecorwin'>         linkedin.com/in/phoebecorwin</a>
            </p>
            <p>github:
                <a href='https://www.github.com/pcorwin'>   github.com/pcorwin</a>
            </p>
        </div>
    </div>
  );
};

export default AboutPage;