import React from 'react';
import './ContPage.css';

const CodePage = () => {
  return (
    <div className="code-page">
      <div className="header-box">
        <h1>code page</h1>
        <a href="/" className="home-button">home</a>
      </div>
      <div className="content-box">
        <h2>basic covid dashboard</h2>
        <p>(coming soon)</p>
      </div>
    </div>
  );
};

export default CodePage;